var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"display-1 font-weight-medium"},[_vm._v("Inventory")]),_c('span',{staticClass:"d-block mt-1"},[_vm._v("This page contains inventory listings for every property available")])]),_c('v-col',{attrs:{"cols":"12","md":"6","align":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","text":"","loading":_vm.loading,"disabled":_vm.loading,"to":"/inventory/upload"}},[_vm._v(" Upload Inventory ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","to":"/inventory/traveler-view","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" View As Traveler ")])],1)],1)],1),_c('v-col',[_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":[
        { text: 'Resort ID', value: 'id' },
        { text: 'Resort Name', value: 'resortName' },
        { text: 'Destination', value: 'destination' },
        { text: 'Source', value: 'source' },
        { text: 'Date Created', value: 'dateSubmitted' },
        { text: 'Edit', value: 'actions', sortable: false } ],"items":_vm.inventoryListings,"items-per-page":-1,"sortBy":"id","loading":_vm.loading,"search":_vm.propertySearch},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Inventory Listings")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"append-icon":"mdi-magnify","label":"Search Properties","single-line":"","hide-details":"","dense":"","outlined":"","clearable":"","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.propertySearch),callback:function ($$v) {_vm.propertySearch=$$v},expression:"propertySearch"}})],1)]},proxy:true},{key:"item",fn:function(data){return [[_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onViewInventoryListing(data.item)}}},[_c('td',[_vm._v(" "+_vm._s(data.item.id.toUpperCase())+" ")]),_c('td',[_vm._v(" "+_vm._s(data.item.resortName)+" ")]),_c('td',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(data.item.destination)+" ")]),_c('td',[_vm._v(_vm._s(data.item.source))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(data.item.dateSubmitted) + " (" + _vm.diffDays(data.item.dateSubmitted) + " days ago)")+" ")]),_c('td',{on:{"click":function (event) {
                  event.stopPropagation();
                  _vm.editInventory(data);
                }}},[_c('v-list-item',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)])]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }