<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12">
      <v-row class="mt-2">
        <v-col cols="12" md="6">
          <h1 class="display-1 font-weight-medium">Inventory</h1>
          <span class="d-block mt-1"
            >This page contains inventory listings for every property
            available</span
          >
        </v-col>
        <v-col cols="12" md="6" align="end"
          ><v-btn
            color="primary"
            text
            :loading="loading"
            :disabled="loading"
            class="mr-3"
            to="/inventory/upload"
          >
            Upload Inventory
          </v-btn>

          <v-btn
            color="primary"
            depressed
            to="/inventory/traveler-view"
            :loading="loading"
            :disabled="loading"
          >
            View As Traveler
          </v-btn></v-col
        >
      </v-row>
    </v-col>
    <!-- page header -->

    <!-- Inventory Listings -->
    <v-col
      ><v-data-table
        dense
        :headers="[
          { text: 'Resort ID', value: 'id' },
          { text: 'Resort Name', value: 'resortName' },
          { text: 'Destination', value: 'destination' },
          { text: 'Source', value: 'source' },
          { text: 'Date Created', value: 'dateSubmitted' },
          { text: 'Edit', value: 'actions', sortable: false },
        ]"
        :items="inventoryListings"
        :items-per-page="-1"
        sortBy="id"
        :loading="loading"
        class="border"
        :search="propertySearch"
      >
        <!-- title -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Inventory Listings</v-toolbar-title>
            <v-spacer />
            <v-text-field
              append-icon="mdi-magnify"
              label="Search Properties"
              single-line
              hide-details
              dense
              outlined
              v-model="propertySearch"
              clearable
              style="max-width: 500px"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </v-toolbar>
        </template>
        <!-- items -->
        <template v-slot:item="data">
          <template>
            <tr
              style="cursor: pointer"
              @click="onViewInventoryListing(data.item)"
            >
              <td>
                {{ data.item.id.toUpperCase() }}
              </td>
              <td>
                {{ data.item.resortName }}
              </td>
              <td class="text-capitalize">
                {{ data.item.destination }}
              </td>
              <td>{{ data.item.source }}</td>
              <td>
                {{
                  formatDate(data.item.dateSubmitted) +
                  " (" +
                  diffDays(data.item.dateSubmitted) +
                  " days ago)"
                }}
              </td>
              <td
                @click="
                  (event) => {
                    event.stopPropagation();
                    editInventory(data);
                  }
                "
              >
                <v-list-item>
                  <!-- <v-list-item-icon> -->
                  <v-icon small class="mr-2"> mdi-pencil </v-icon>
                  <!-- </v-list-item-icon> -->
                </v-list-item>
              </td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </v-col>
    <!-- Inventory Listings -->
  </v-row>
</template>

<script>
import { portalsApp } from "@/firebase";
import moment from "moment";
const inventoryListings = portalsApp
  .firestore()
  .collection("inventoryListings");
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("inventoryListings", inventoryListings);
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    inventoryListings: [],
    propertySearch: "",
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    editInventory(data) {
      this.$store.state.inventoryId = { id: data.item.id, editing: true };

      this.$router.push("/inventory/new");
    },
    onViewInventoryListing({ id }) {
      this.$router.push(`/inventory/${id}`);
    },
    diffDays(date) {
      if (date) {
        let a = moment(new Date());
        let b = moment(date);
        let diffDays = a.diff(b, "days");
        return diffDays;
      }
    },
  },
};
</script>
